<template>
  <CContainer class="mt-5">
    <CRow class="justify-content-center">
      <CCol
        col="12"
        lg="7"
      >
        <CCard class="p-4">
          <CCardBody>
            <router-view />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
  name: 'Index',
  mounted() {
    console.log('INDEX', this.$route, this.isAuthorized);
    if (this.isAuthorized) {
      this.$router.replace('/admin');
      return;
    }

    if (this.$route.path == '/') {
      return this.$router.replace({name: 'login'})
    }
  },
  computed: {
    ...mapGetters(['isAuthorized'])
  }
};
</script>

<style scoped>

</style>
